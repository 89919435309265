export const API_URL 							= 'https://api.rulle.as'

export const FIREBASE_CONFIG = {
	authDomain: 'rulle.as',
	projectId: 'rullejs',
	messagingSenderId: '2515460557',
	storageBucket: 'rullejs.appspot.com',
	appId: '1:2515460557:web:8cd09ab4caf6666c4de484',
	apiKey: 'AIzaSyCd8d3zf9gaybqhLgExUvG4XKMWuzdYoNw'
}

export const ATTACHMENT_MIMES			= ['image/png', 'image/jpeg']

export const USERS 								= 'users'
export const USER_UPDATED 				= 'USER_UPDATED'
export const USER_LOADING 				= 'USER_LOADING'
export const USER_LOGGED_IN 			= 'USER_LOGGED_IN'
export const USER_LOGGED_OUT 			= 'USER_LOGGED_OUT'

export const REPLIES 							= 'replies'
export const REPLIES_LOADED				= 'REPLIES_LOADED'
export const REPLIES_RESET 				= 'REPLIES_RESET'
export const REPLIES_LOADING			= 'REPLIES_LOADING'
export const REPLIES_NEW_ITEM 		= 'REPLIES_NEW_ITEM'
export const REPLIES_LOADED_ALL 	= 'REPLIES_LOADED_ALL'
export const REPLIES_LOADED_MORE 	= 'REPLIES_LOADED_MORE'
export const REPLIES_PER_REQUEST 	= 10

export const TICKETS 							= 'tickets'
export const TICKETS_LOADED 			= 'TICKETS_LOADED'
export const TICKETS_LOADING 			= 'TICKETS_LOADING'

export const WARNINGS 						= 'warnings'
export const VERIFICATIONS 				= 'verifications'