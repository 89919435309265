import {useDispatch, useSelector} from 'react-redux'
import {useState} from 'react'
import Hashids from 'hashids'

import {
	setStaticOTP,
	removeStaticOTP
} from '../../actions/user'

export default function OTP() {

	const dispatch = useDispatch()

	const user = useSelector(state => state.user)

	const hashids = new Hashids(user.phoneNumber, 6, 'aBcDeFgHiJkKmMoPqRsTuVwXyZ')
	const [code, setCode] = useState(String(user.otp ? hashids.decode(user.otp) : ''))

	const onSubmit = () => {

		if (user.loading || !window.confirm('Are you sure?'))
			return false

		// remove PIN if previously set
		if (user.otp)
			return dispatch(removeStaticOTP(() => setCode('')))

		// set PIN
		dispatch(setStaticOTP(code))

	}

	return (
		<div className="box mb-3 p-3">

			<div className="d-flex align-items-center mb-3">
				<i className="fas fs-1 fa-exclamation-square opacity-75 me-3" />
				<div>
					The form below can be used to set a <b>static PIN</b>. After setting it, you will not be sent a verification SMS anymore when trying to authenticate.
				</div>
			</div>

			<div className="input-group">
				<span className="input-group-text">
					<i className="far fa-fw fa-key" />
				</span>
				<input type="text" className="form-control" placeholder={`eg. ${Math.floor(1000 + Math.random() * 9000).toString()}`} value={code} disabled={user.otp} maxLength={4} onChange={e => setCode(e.target.value.trim().replaceAll(/\D/g, ''))} />
				<button className={user.otp ? 'btn btn-danger' : 'btn btn-success'} onClick={onSubmit} disabled={user.loading || code.length !== 4}>
					{user.loading
						? <i className="fas fa-spin fa-spinner-third" />
						: (user.otp ? 'REMOVE PIN' : 'SET PIN')
					}
				</button>
			</div>
		</div>
	)

}