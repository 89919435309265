import thunk from 'redux-thunk'

import {
  createStore,
  applyMiddleware,
  combineReducers
} from 'redux'

import tickets from './tickets'
import replies from './replies'
import user from './user'

const reducers = {
	tickets,
  replies,
  user
}

// Store
export default createStore(
	combineReducers(reducers),
	applyMiddleware(thunk)
)