import {BrowserRouter, Routes, Route, NavLink, Link, Navigate} from 'react-router-dom'
import {Provider, useDispatch, useSelector} from 'react-redux'
import parsePhoneNumber from 'libphonenumber-js'
import {ToastContainer} from 'react-toastify'
import {createRoot} from 'react-dom/client'
import {useEffect} from 'react'
import store from './reducers'
import moment from 'moment'

import {
	fetchWarnings,
	detectLoginStatus
} from './actions/user'

// components
import Otp from './components/Otp'
import Tickets from './components/Tickets'
import Logout from './components/Logout'
import Connect from './components/Connect'

// import css
import 'react-toastify/dist/ReactToastify.min.css'
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css'

// disable react-dev-tools
if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object")
	for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__))
		window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value == "function" ? () => {} : null;

window.jQuery = require('jquery')
window.fancybox = require('@fancyapps/fancybox')

function App() {

	const dispatch = useDispatch()

	const user = useSelector(state => state.user)

	useEffect(() => {
		dispatch(fetchWarnings())
		dispatch(detectLoginStatus())
	}, [dispatch])

	// user is not authenticated
	if (!user.objectId)
		return <Connect />

	return (
		<div className="container-fluid py-3">

			<div className="box p-2 d-flex mb-3 align-items-center">
				<div className="ps-2 fw-bold">{user.name || parsePhoneNumber(user.phoneNumber).formatInternational()}</div>
				<div className="separator" />
				<Link className="btn btn-sm btn-danger" to="/logout">Logout</Link>
			</div>

			{user.warning &&
				<div className={`alert alert-${user.warning.type} d-flex align-items-center mb-3`}>
					<i className="fas fs-2 d-none d-md-block fa-circle-exclamation opacity-75 me-3" />
					<div>
						<b>{moment(user.warning.createdAt.toDate()).format('DD MMMM [at] HH:mm')}</b>
						<br />
						{user.warning.message}
					</div>
				</div>
			}

			{user.verification &&
				<div className="alert alert-success d-flex align-items-center mb-3">
					<i className="fas fs-2 fa-message-sms opacity-75 me-3" />
					<div>
						Your verification code is <b>{user.verification.code}</b>
					</div>
				</div>
			}

			<div className="row">
				<div className="col-12 col-md-3">
					<ul className="list-group mb-3">
						<NavLink className="list-group-item" to="/tickets">
							<i className="far fa-fw fa-headset" /> Tickets
						</NavLink>
						<NavLink className="list-group-item" to="/otp">
							<i className="far fa-fw fa-key" /> SMS Code
						</NavLink>
					</ul>
				</div>
				<div className="col-12 col-md-9">
					<Routes>
						<Route path="/tickets/*" element={<Tickets />} />
						<Route path="/otp" element={<Otp />} />
						<Route path="/logout" element={<Logout />} />
						<Route path="*" element={<Navigate to="/tickets" />} />
					</Routes>
				</div>
			</div>


		</div>
	)

}

const container = document.getElementById('app')
const root = createRoot(container)

root.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
			<ToastContainer />
		</BrowserRouter>
	</Provider>
)
