import {
	TICKETS_LOADED,
	TICKETS_LOADING
} from '../constants'

const initialState = {
	loading: true,
	list: []
}

export default function Tickets(state = initialState, action) {
	switch (action.type) {
		case TICKETS_LOADED:
			return {
				...state,
				loading: false,
				list: action.data || []
			}
		case TICKETS_LOADING:
			return {
				...state,
				loading: true
			}
		default:
			return state
	}
}