import TextareaAutosize from 'react-textarea-autosize'
import {useSelector, useDispatch} from 'react-redux'
import {ATTACHMENT_MIMES} from '../../../constants'
import {Link, useNavigate} from 'react-router-dom'
import {create} from '../../../actions/ticket'
import {showMessage} from '../../../utils'
import {useState, useRef} from 'react'

export default function Create() {

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const attachment = useRef()

	const user = useSelector(state => state.user)

	const [state, changeState] = useState({
		subject: '',
		message: '',
		attachment: null,
		account: user.accounts.length === 1 ? user.accounts[0] : ''
	})

	const setState = data => changeState(prevState => ({...prevState, ...data}))

	const onAttachmentChange = async e => {

		const file = e.target.files[0]
		e.target.value = null

		if (!file)
			return false

		if (!ATTACHMENT_MIMES.includes(file.type))
			return showMessage('Only JPG and PNG files are supported! Try again.')

		setState({attachment: `File (${(file.size/1048576).toFixed(2)}MB)`})
		attachment.current = file

	}

	const onRemoveAttachment = () => {
		attachment.current = null
		setState({attachment: null})
	}

	const onCreate = () => dispatch(create({
		account: state.account,
		message: state.message.trim(),
		subject: state.subject.trim(),
		attachment: state.attachment ? attachment.current : null
	}, navigate))

	return (

		<>
			<div className="mb-3">
				<div className="d-flex align-items-center">
					<Link to="/tickets" className="btn btn-sm btn-outline-secondary me-2">
						<i className="fas fa-fw fa-chevron-left" />
					</Link>
					<b className="text-overflow">Create ticket</b>
				</div>
			</div>

			<input type="text" className="form-control mb-3" placeholder="Subject" value={state.subject} onChange={e => setState({subject: e.target.value})} />

			<div className="box my-2">
				<TextareaAutosize
					minRows={2}
					maxRows={7}
					value={state.message}
					style={{resize: 'none'}}
					placeholder="Write message .."
					className="border-0 p-3 w-100"
					onChange={e => setState({message: e.target.value})}
				/>
				<hr className="bg-success bg-opacity-75 m-0" />
				<div className="d-flex align-items-center justify-content-between bg-light p-2">
					{state.attachment ?
						<div className="input-group input-group-sm">
							<button className="btn btn-danger" onClick={onRemoveAttachment} disabled={user.loading}>
								<i className="fal fa-fw fa-times" />
							</button>
							<div className="input-group-text">
								{user.loading ? 'Uploading' : state.attachment}
							</div>
						</div>
						:
						<label className="btn btn-sm btn-outline-secondary" disabled={user.loading}>
							<input type="file" className="d-none" accept={ATTACHMENT_MIMES} onChange={onAttachmentChange} />
							<i className="fal fa-paperclip" />
						</label>
					}
					<div className="input-group input-group-sm flex-shrink-0 w-auto">
						{user.accounts.length > 1 &&
							<select className="form-select w-auto" value={state.account} onChange={e => setState({account: e.target.value})}>
								<option value="" disabled>Country</option>
								{user.accounts.map(id =>
									<option key={id} value={id}>{id.toUpperCase()}</option>
								)}
							</select>
						}
						<button className="btn btn-success" disabled={user.loading || !state.subject.trim().length || !state.message.trim().length || !state.account.length} onClick={onCreate}>CREATE</button>
					</div>
				</div>
			</div>

		</>
	)

}
