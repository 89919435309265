import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import moment from 'moment'

export default function List() {

	const user = useSelector(state => state.user)
	const tickets = useSelector(state => state.tickets)

	return (
		<>
			<ul className="nav nav-tabs align-items-center">
				<li className="nav-item">
					<span className="nav-link active fw-bold">Tickets</span>
				</li>
				<div className="separator"></div>
				<li className="nav-item me-2">
					<Link to="/tickets/create" className="btn btn-sm btn-success">Create</Link>
				</li>
			</ul>

			<div className="box list-group-flush noTop mb-3">
				{!!tickets.list.length && tickets.list.map(item => {

					let isNew = item.lastReplyBy !== user.objectId

					return (
						<Link key={item.objectId} to={'/tickets/' + item.objectId} className="list-group-item list-group-item-action px-3 py-2 d-flex align-items-center">
							<div className="flex-grow-1 text-overflow">
								<div className="d-flex align-items-center">
									{isNew && <span className="badge bg-success me-2">NEW</span>}
									<span className={isNew ? 'fw-bold' : ''}>{item.subject}</span>
								</div>
								<small className="text-muted">{item.lastReply}</small>
							</div>
							<div className="text-end flex-shrink-0">
								{moment(item.lastReplyAt.toDate()).format('D MMM YYYY')}
								<br />
								<small className="text-muted">{item.account.toUpperCase()}</small>
							</div>
						</Link>
					)

				})}

				{(!tickets.list.length && !tickets.loading) &&
					<div className="text-danger p-3">No tickets to show yet</div>
				}

			</div>

		</>
	)

}