import {logout} from '../../actions/user'
import {useDispatch} from 'react-redux'
import {useEffect} from 'react'

export default function Logout() {

	const dispatch = useDispatch()

	useEffect(() => {
		setTimeout(() => dispatch(logout()), 1500)
	}, [dispatch])

	return (
		<div className="text-center py-5">
			<div className="spinner-border mb-2"></div>
			<br />
			logging out
		</div>
	)

}