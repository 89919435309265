import {request, showMessage} from '../utils'
import {db, auth} from '../utils/firebase'
import {fetchTickets} from './ticket'

import {
	signOut,
	onAuthStateChanged,
	signInWithPhoneNumber
} from 'firebase/auth'

import {
	doc,
	query,
	where,
	limit,
	getDoc,
	collection,
	onSnapshot
} from 'firebase/firestore'

import {
	USERS,
	WARNINGS,
  USER_LOADING,
	USER_LOGGED_IN,
	USER_LOGGED_OUT,
	VERIFICATIONS,
	USER_UPDATED
} from '../constants'

export const unsubscribe = {}

/**
 * Auto-login status detection
 */
export const detectLoginStatus = () => dispatch =>
	onAuthStateChanged(auth, async user => {

		dispatch({
			type: USER_LOADING,
			data: true
		})

		if (!user)
			return dispatch({type: USER_LOGGED_OUT})

		// fetch user data
		let profile = await getDoc(doc(db, USERS, user.uid))
			.then(snapshot => snapshot.exists() ? snapshot.data() : {})
			.catch(() => ({}))

		dispatch({
			type: USER_LOGGED_IN,
			data: {
				otp: profile.otp,
				objectId: user.uid,
				name: profile.name,
				phoneNumber: user.phoneNumber,
				accounts: profile.accounts || ['norway']
			}
		})

		dispatch(fetchTickets())
		dispatch(fetchPendingOTP())

 })

/**
 * Authentication method
 * @param {object} data Login credentials
 * @param {function} onFailure
 */
export const login = (data, onFailure) => async dispatch => {

	dispatch({
		type: USER_LOADING,
		data: true
	})

	try {
		await signInWithPhoneNumber(auth, data.prefix + data.phone, window.recaptchaVerifier)
			.then(result => {
				const verificationCode = window.prompt("Please enter the verification code that was sent to your mobile device.")
        return result.confirm(verificationCode)
			})
	} catch (err) {
		onFailure()
		dispatch(loginFailed(err.message))
	}

}

/**
 * Login failed
 * @param {string} message Error message
 * @returns {string}
 */
function loginFailed(message) {
	showMessage(message)
	return {
		type: USER_LOADING,
		data: false
	}
}

/**
 * Logout method
 */
export const logout = () => () => {

	const {uid} = auth.currentUser || {}

	if (!uid)
		return window.location.href = '/'

	// unbind listeners
	Object.keys(unsubscribe).forEach(prop => {
		unsubscribe[prop]()
		delete unsubscribe[prop]
	})

	signOut(auth).then(() => window.location.href = '/')

}

/**
 * Fetch pending OTP
 */
export const fetchPendingOTP = () => dispatch => {

	if (!auth.currentUser?.uid)
		return false

	if (unsubscribe.otp)
		unsubscribe.otp()

	let q = query(
		collection(db, VERIFICATIONS),
		where('number', '==', auth.currentUser.phoneNumber),
		limit(1)
	)

	unsubscribe.otp = onSnapshot(q, snapshot => {
		dispatch({
			type: USER_UPDATED,
			data: {
				verification: snapshot.empty ? false : snapshot.docs[0].data()
			}
		})
	})

}

/**
 * Set static OTP
 * @param {string} code Static PIN code
 */
export const setStaticOTP = code => async dispatch => {

	dispatch({
		type: USER_LOADING,
		data: true
	})

	let response = await request('/customer/pin', {code})

	dispatch({
		type: USER_LOADING,
		data: false
	})

	if (response.error)
		return showMessage(response.error)

	dispatch({
		type: USER_UPDATED,
		data: {otp: response.hash}
	})

	showMessage('Static PIN successfully set', 'success')

}

/**
 * Remove static OTP
 */
export const removeStaticOTP = onSuccess => async dispatch => {

	dispatch({
		type: USER_LOADING,
		data: true
	})

	const response = await request('/customer/no-pin')

	dispatch({
		type: USER_LOADING,
		data: false
	})

	if (response.error)
		return showMessage(response.error)

	dispatch({
		type: USER_UPDATED,
		data: {otp: false}
	})

	onSuccess()
	showMessage('Static PIN has been removed', 'success')

}

/**
 * Fetch active warning
 */
export const fetchWarnings = () => dispatch => {

	if (unsubscribe.warnings)
		unsubscribe.warnings()

	let q = query(
		collection(db, WARNINGS),
		where('active', '==', true),
		limit(1)
	)

	unsubscribe.warnings = onSnapshot(q, snapshot => {
		dispatch({
			type: USER_UPDATED,
			data: {
				warning: snapshot.empty ? false : snapshot.docs[0].data()
			}
		})
	})

}
