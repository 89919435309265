import {Routes, Route} from 'react-router-dom'

import List from './List'
import Create from './Create'
import Ticket from './Ticket'

export default function Tickets() {
  return (
    <Routes>
      <Route path=":ticketId" element={<Ticket />} />
      <Route path="create" element={<Create />} />
      <Route index element={<List />} />
    </Routes>
  )
}