import {useDispatch, useSelector} from 'react-redux'
import {RecaptchaVerifier} from 'firebase/auth'
import COUNTRIES from '../../utils/countries'
import {auth} from '../../utils/firebase'
import {useEffect, useState} from 'react'
import {login} from '../../actions/user'
import moment from 'moment'

export default function Connect() {

	const dispatch = useDispatch()

	const user = useSelector(state => state.user)

	const [state, changeState] = useState({
		prefix: localStorage.getItem('prefix') || '+1',
		verified: false,
		phone: ''
	})

	const setState = data => changeState(prevState => ({...prevState, ...data}))

	const onInputChange = e => {

		if (e.target.name === 'prefix')
			localStorage.setItem('prefix', e.target.value)

		setState({[e.target.name]: e.target.name === 'phone' ? e.target.value : e.target.value.replace(/^[0-9]+/g, '')})

	}

	useEffect(() => {

		window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
			'size': 'normal',
			'callback': () => setState({verified: true}),
			'expired-callback': () => setState({verified: false})
		}, auth)

		window.recaptchaVerifier.render()

	}, [])

	const onSubmit = () => {

		if (!state.verified)
			return false

		dispatch(login(state, () => {
			setState({verified: false})
			window.recaptchaVerifier.recaptcha.reset()
		}))

	}

	return (
		<div className="container-fluid">

			{user.warning &&
				<div className={`alert alert-${user.warning.type} d-flex align-items-center my-3`}>
					<i className="fas fs-2 d-none d-md-block fa-circle-exclamation opacity-75 me-3" />
					<div>
						<b>{moment(user.warning.createdAt.toDate()).format('DD MMMM [at] HH:mm')}</b>
						<br />
						{user.warning.message}
					</div>
				</div>
			}

			<div className="connect">

				<div className="text-muted text-center mb-4">In case you encounter issues with the app, this dashboard allows you to get in touch with us</div>

				<div className="input-group mb-3">
					<select name="prefix" className="form-control" value={state.prefix} onChange={onInputChange} disabled={user.loading}>
						{COUNTRIES.sort((a, b) => a.prefix.localeCompare(b.prefix)).map(country =>
							<option key={country.code} value={country.prefix}>
								{country.prefix}
							</option>
						)}
					</select>
					<input
						type="phone"
						name="phone"
						maxLength={15}
						value={state.phone}
						disabled={user.loading}
						onChange={onInputChange}
						placeholder="Phone number"
						className="form-control w-auto"
					/>
				</div>

				<div id="recaptcha-container" />

				<div className="d-grid mt-3">
					<button className="btn btn-success" onClick={onSubmit} disabled={user.loading || !state.verified || state.phone.length < 7}>
						{user.loading ? <i className="fas fa-spin fa-spinner-third" /> : 'AUTHENTICATE'}
					</button>
				</div>

			</div>
		</div>
 )

}
