import {Link, useNavigate, useParams} from 'react-router-dom'
import TextareaAutosize from 'react-textarea-autosize'
import {useSelector, useDispatch} from 'react-redux'
import {useEffect, useRef, useState} from 'react'
import {showMessage} from '../../../utils'
import moment from 'moment'

import {
	REPLIES_RESET,
	ATTACHMENT_MIMES
} from '../../../constants'

import {
	reply,
	fetchReplies
} from '../../../actions/ticket'

export default function Ticket() {

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const attachment = useRef()
	const {ticketId} = useParams()

	const user = useSelector(state => state.user)
	const tickets = useSelector(state => state.tickets)
	const replies = useSelector(state => state.replies)

	const [state, changeState] = useState({
		message: '',
		attachment: null
	})

	const setState = data => changeState(prevState => ({...prevState, ...data}))

	// fetch replies
	useEffect(() => {

		dispatch(fetchReplies(ticketId, true))

		return () => {
			dispatch({type: REPLIES_RESET})
		}

	}, [dispatch, navigate, ticketId])

	const onAttachmentChange = async e => {

		const file = e.target.files[0]
		e.target.value = null

		if (!file)
			return false

		if (!ATTACHMENT_MIMES.includes(file.type))
			return showMessage('Only JPG and PNG files are supported! Try again.')

		setState({attachment: `File (${(file.size/1048576).toFixed(2)}MB)`})
		attachment.current = file

	}

	const onRemoveAttachment = () => {
		attachment.current = null
		setState({attachment: null})
	}

	const onReply = () => dispatch(reply(ticketId, {
		message: state.message.trim(),
		attachment: state.attachment ? attachment.current : null
	}, () => setState({
		message: '',
		attachment: null
	})))

	if (tickets.loading)
		return (
			<div className="box p-5 text-center">
				<div className="spinner-border" />
			</div>
		)

	// find ticket
	let ticket = tickets.list.find(item => item.objectId === ticketId) || {}

	// ticket doesn't exist
	if (!ticket.objectId)
		return (
			<div className="box p-3 text-muted">
				Specified ticket does not exist. Click <Link to="/tickets">here</Link> to go back.
			</div>
		)

	return (
		<>
			<div className="box p-3 mb-3">
				<div className="d-flex align-items-center">
					<Link to="/tickets" className="btn btn-sm btn-outline-secondary me-2">
						<i className="fas fa-fw fa-chevron-left" />
					</Link>
					<b className="text-overflow">{ticket.subject}</b>
				</div>
			</div>

			{/* LOAD PREVIOUS */}
			{!replies.noMoreToLoad &&
				<div className="text-center mb-3">
					<button className="btn btn-sm btn-outline-secondary me-2" onClick={() => dispatch(fetchReplies(ticketId))} disabled={replies.loading}>
						{replies.loading ? <i className="fas fa-spin fa-spinner-third" /> : 'load previous'}
					</button>
				</div>
			}

			<div className="d-flex flex-column">
				{/* REPLIES LISTING */}
				{replies.list.map(item => {

					let isOperator = !!item.operator
					let isSelf = item.operator === user.objectId

					return (
						<div key={item.objectId} className={'card reply-message mb-2' + (isOperator ? '' : ' text-white bg-dark align-self-end text-end')}>
							<div className={`card-header bg-gradient d-flex align-items-center ${isOperator ? 'pe-2' : ''}`}>

								{/* ATTACHMENT */}
								{item.attachment &&
									<button
										href={item.attachment}
										data-hash="false"
										data-protect="true"
										data-fancybox="gallery"
										data-back-focus={false}
										data-animation-effect="fade"
										disabled={user.loading || replies.length <= 1}
										className="btn btn-sm btn-primary bg-gradient me-2"
									>
										<i className="far fa-paperclip" />
									</button>
								}
								{/* SENDER */}
								<b>{isOperator ? (isSelf ? 'You' : 'Operator') : 'Customer'}</b>

								<div className="separator" />

								{/* TIMESTAMP */}
								<small className="opacity-50">{moment(item.createdAt?.toDate()).fromNow()}</small>

							</div>
							<div className="card-body">
								<p className="card-text pre-line">{item.message.replace(/\n\s*\n/g, '\n')}</p>
							</div>
						</div>
					)

				})}
			</div>

			<hr />

			<div className="box my-2">
				<TextareaAutosize
					minRows={2}
					maxRows={7}
					value={state.message}
					style={{resize: 'none'}}
					placeholder="Write message .."
					className="border-0 p-3 w-100"
					onChange={e => setState({message: e.target.value})}
				/>
				<hr className="bg-success bg-opacity-75 m-0" />
				<div className="d-flex align-items-center bg-light p-2">
					{state.attachment ?
						<div className="input-group input-group-sm">
							<button className="btn btn-danger" onClick={onRemoveAttachment} disabled={user.loading}>
								<i className="fal fa-fw fa-times" />
							</button>
							<div className="input-group-text">
								{user.loading ? 'Uploading' : state.attachment}
							</div>
						</div>
						:
						<label className="btn btn-sm btn-outline-secondary" disabled={user.loading}>
							<input type="file" className="d-none" accept={ATTACHMENT_MIMES} onChange={onAttachmentChange} />
							<i className="fal fa-paperclip" />
						</label>
					}
					<div className="separator" />
					<button className="btn btn-sm btn-success" disabled={user.loading || !state.message.trim().length} onClick={onReply}>REPLY</button>
				</div>
			</div>

		</>
	)

}
