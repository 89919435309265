const COUNTRIES = [
	{code: 'AF', prefix: '+93', name: "Afghanistan"},
	{code: 'AX', prefix: '+358', name: "Aland Islands"},
	{code: 'AL', prefix: '+355', name: "Albania"},
	{code: 'DZ', prefix: '+213', name: "Algeria"},
	{code: 'AS', prefix: '+1684', name: "American Samoa"},
	{code: 'AD', prefix: '+376', name: "Andorra"},
	{code: 'AO', prefix: '+244', name: "Angola"},
	{code: 'AI', prefix: '+1264', name: "Anguilla"},
	{code: 'AQ', prefix: '+672', name: "Antarctica"},
	{code: 'AG', prefix: '+1268', name: "Antigua and Barbuda"},
	{code: 'AR', prefix: '+54', name: "Argentina"},
	{code: 'AM', prefix: '+374', name: "Armenia"},
	{code: 'AW', prefix: '+297', name: "Aruba"},
	{code: 'AU', prefix: '+61', name: "Australia"},
	{code: 'AT', prefix: '+43', name: "Austria"},
	{code: 'AZ', prefix: '+994', name: "Azerbaijan"},
	{code: 'BS', prefix: '+1242', name: "Bahamas"},
	{code: 'BH', prefix: '+973', name: "Bahrain"},
	{code: 'BD', prefix: '+880', name: "Bangladesh"},
	{code: 'BB', prefix: '+1246', name: "Barbados"},
	{code: 'BY', prefix: '+375', name: "Belarus"},
	{code: 'BE', prefix: '+32', name: "Belgium"},
	{code: 'BZ', prefix: '+501', name: "Belize"},
	{code: 'BJ', prefix: '+229', name: "Benin"},
	{code: 'BM', prefix: '+1441', name: "Bermuda"},
	{code: 'BT', prefix: '+975', name: "Bhutan"},
	{code: 'BO', prefix: '+591', name: "Bolivia"},
	{code: 'BA', prefix: '+387', name: "Bosnia and Herzegovina"},
	{code: 'BW', prefix: '+267', name: "Botswana"},
	{code: 'BR', prefix: '+55', name: "Brazil"},
	{code: 'IO', prefix: '+246', name: "British Indian Ocean Territory"},
	{code: 'BN', prefix: '+673', name: "Brunei Darussalam"},
	{code: 'BG', prefix: '+359', name: "Bulgaria"},
	{code: 'BF', prefix: '+226', name: "Burkina Faso"},
	{code: 'BI', prefix: '+257', name: "Burundi"},
	{code: 'KH', prefix: '+855', name: "Cambodia"},
	{code: 'CM', prefix: '+237', name: "Cameroon"},
	{code: 'CV', prefix: '+238', name: "Cape Verde"},
	{code: 'KY', prefix: '+1345', name: "Cayman Islands"},
	{code: 'CF', prefix: '+236', name: "Central African Republic"},
	{code: 'TD', prefix: '+235', name: "Chad"},
	{code: 'CL', prefix: '+56', name: "Chile"},
	{code: 'CN', prefix: '+86', name: "China"},
	{code: 'CX', prefix: '+61', name: "Christmas Island"},
	{code: 'CC', prefix: '+61', name: "Cocos Islands"},
	{code: 'CO', prefix: '+57', name: "Colombia"},
	{code: 'KM', prefix: '+269', name: "Comoros"},
	{code: 'CG', prefix: '+242', name: "Congo"},
	{code: 'CD', prefix: '+243', name: "Congo"},
	{code: 'CK', prefix: '+682', name: "Cook Islands"},
	{code: 'CR', prefix: '+506', name: "Costa Rica"},
	{code: 'CI', prefix: '+225', name: "Cote d'Ivoire"},
	{code: 'HR', prefix: '+385', name: "Croatia"},
	{code: 'CU', prefix: '+53', name: "Cuba"},
	{code: 'CY', prefix: '+357', name: "Cyprus"},
	{code: 'CZ', prefix: '+420', name: "Czech Republic"},
	{code: 'DK', prefix: '+45', name: "Denmark"},
	{code: 'DJ', prefix: '+253', name: "Djibouti"},
	{code: 'DM', prefix: '+1767', name: "Dominica"},
	{code: 'DO', prefix: '+1849', name: "Dominican Republic"},
	{code: 'EC', prefix: '+593', name: "Ecuador"},
	{code: 'EG', prefix: '+20', name: "Egypt"},
	{code: 'SV', prefix: '+503', name: "El Salvador"},
	{code: 'GQ', prefix: '+240', name: "Equatorial Guinea"},
	{code: 'ER', prefix: '+291', name: "Eritrea"},
	{code: 'EE', prefix: '+372', name: "Estonia"},
	{code: 'ET', prefix: '+251', name: "Ethiopia"},
	{code: 'FK', prefix: '+500', name: "Falkland Islands"},
	{code: 'FO', prefix: '+298', name: "Faroe Islands"},
	{code: 'FJ', prefix: '+679', name: "Fiji"},
	{code: 'FI', prefix: '+358', name: "Finland"},
	{code: 'FR', prefix: '+33', name: "France"},
	{code: 'GF', prefix: '+594', name: "French Guiana"},
	{code: 'PF', prefix: '+689', name: "French Polynesia"},
	{code: 'GA', prefix: '+241', name: "Gabon"},
	{code: 'GM', prefix: '+220', name: "Gambia"},
	{code: 'GE', prefix: '+995', name: "Georgia"},
	{code: 'DE', prefix: '+49', name: "Germany"},
	{code: 'GH', prefix: '+233', name: "Ghana"},
	{code: 'GI', prefix: '+350', name: "Gibraltar"},
	{code: 'GR', prefix: '+30', name: "Greece"},
	{code: 'GL', prefix: '+299', name: "Greenland"},
	{code: 'GD', prefix: '+1473', name: "Grenada"},
	{code: 'GP', prefix: '+590', name: "Guadeloupe"},
	{code: 'GU', prefix: '+1671', name: "Guam"},
	{code: 'GT', prefix: '+502', name: "Guatemala"},
	{code: 'GG', prefix: '+44', name: "Guernsey"},
	{code: 'GN', prefix: '+224', name: "Guinea"},
	{code: 'GW', prefix: '+245', name: "Guinea-Bissau"},
	{code: 'GY', prefix: '+595', name: "Guyana"},
	{code: 'HT', prefix: '+509', name: "Haiti"},
	{code: 'VA', prefix: '+379', name: "Holy See (Vatican)"},
	{code: 'HN', prefix: '+504', name: "Honduras"},
	{code: 'HK', prefix: '+852', name: "Hong Kong"},
	{code: 'HU', prefix: '+36', name: "Hungary"},
	{code: 'IS', prefix: '+354', name: "Iceland"},
	{code: 'IN', prefix: '+91', name: "India"},
	{code: 'ID', prefix: '+62', name: "Indonesia"},
	{code: 'IR', prefix: '+98', name: "Iran"},
	{code: 'IQ', prefix: '+964', name: "Iraq"},
	{code: 'IE', prefix: '+353', name: "Ireland"},
	{code: 'IM', prefix: '+44', name: "Isle of Man"},
	{code: 'IL', prefix: '+972', name: "Israel"},
	{code: 'IT', prefix: '+39', name: "Italy"},
	{code: 'JM', prefix: '+1876', name: "Jamaica"},
	{code: 'JP', prefix: '+81', name: "Japan"},
	{code: 'JE', prefix: '+44', name: "Jersey"},
	{code: 'JO', prefix: '+962', name: "Jordan"},
	{code: 'KZ', prefix: '+77', name: "Kazakhstan"},
	{code: 'KE', prefix: '+254', name: "Kenya"},
	{code: 'KI', prefix: '+686', name: "Kiribati"},
	{code: 'KP', prefix: '+850', name: "Korea (North)"},
	{code: 'KR', prefix: '+82', name: "Korea (South)"},
	{code: 'KW', prefix: '+965', name: "Kuwait"},
	{code: 'KG', prefix: '+996', name: "Kyrgyzstan"},
	{code: 'LA', prefix: '+856', name: "Laos"},
	{code: 'LV', prefix: '+371', name: "Latvia"},
	{code: 'LB', prefix: '+961', name: "Lebanon"},
	{code: 'LS', prefix: '+266', name: "Lesotho"},
	{code: 'LR', prefix: '+231', name: "Liberia"},
	{code: 'LY', prefix: '+218', name: "Libyan Arab Jamahiriya"},
	{code: 'LI', prefix: '+423', name: "Liechtenstein"},
	{code: 'LT', prefix: '+370', name: "Lithuania"},
	{code: 'LU', prefix: '+352', name: "Luxembourg"},
	{code: 'MO', prefix: '+853', name: "Macao"},
	{code: 'MK', prefix: '+389', name: "Macedonia"},
	{code: 'MG', prefix: '+261', name: "Madagascar"},
	{code: 'MW', prefix: '+265', name: "Malawi"},
	{code: 'MY', prefix: '+60', name: "Malaysia"},
	{code: 'MV', prefix: '+960', name: "Maldives"},
	{code: 'ML', prefix: '+223', name: "Mali"},
	{code: 'MT', prefix: '+356', name: "Malta"},
	{code: 'MH', prefix: '+692', name: "Marshall Islands"},
	{code: 'MQ', prefix: '+596', name: "Martinique"},
	{code: 'MR', prefix: '+222', name: "Mauritania"},
	{code: 'MU', prefix: '+230', name: "Mauritius"},
	{code: 'YT', prefix: '+262', name: "Mayotte"},
	{code: 'MX', prefix: '+52', name: "Mexico"},
	{code: 'FM', prefix: '+691', name: "Micronesia"},
	{code: 'MD', prefix: '+373', name: "Moldova"},
	{code: 'MC', prefix: '+377', name: "Monaco"},
	{code: 'MN', prefix: '+976', name: "Mongolia"},
	{code: 'ME', prefix: '+382', name: "Montenegro"},
	{code: 'MS', prefix: '+1664', name: "Montserrat"},
	{code: 'MA', prefix: '+212', name: "Morocco"},
	{code: 'MZ', prefix: '+258', name: "Mozambique"},
	{code: 'MM', prefix: '+95', name: "Myanmar"},
	{code: 'NA', prefix: '+264', name: "Namibia"},
	{code: 'NR', prefix: '+674', name: "Nauru"},
	{code: 'NP', prefix: '+977', name: "Nepal"},
	{code: 'NL', prefix: '+31', name: "Netherlands"},
	{code: 'AN', prefix: '+599', name: "Netherlands Antilles"},
	{code: 'NC', prefix: '+687', name: "New Caledonia"},
	{code: 'NZ', prefix: '+64', name: "New Zealand"},
	{code: 'NI', prefix: '+505', name: "Nicaragua"},
	{code: 'NE', prefix: '+227', name: "Niger"},
	{code: 'NG', prefix: '+234', name: "Nigeria"},
	{code: 'NU', prefix: '+683', name: "Niue"},
	{code: 'NF', prefix: '+672', name: "Norfolk Island"},
	{code: 'MP', prefix: '+1670', name: "Northern Mariana Islands"},
	{code: 'NO', prefix: '+47', name: "Norway"},
	{code: 'OM', prefix: '+968', name: "Oman"},
	{code: 'PK', prefix: '+92', name: "Pakistan"},
	{code: 'PW', prefix: '+680', name: "Palau"},
	{code: 'PS', prefix: '+970', name: "Palestinian Territory"},
	{code: 'PA', prefix: '+507', name: "Panama"},
	{code: 'PG', prefix: '+675', name: "Papua New Guinea"},
	{code: 'PY', prefix: '+595', name: "Paraguay"},
	{code: 'PE', prefix: '+51', name: "Peru"},
	{code: 'PH', prefix: '+63', name: "Philippines"},
	{code: 'PN', prefix: '+872', name: "Pitcairn"},
	{code: 'PL', prefix: '+48', name: "Poland"},
	{code: 'PT', prefix: '+351', name: "Portugal"},
	{code: 'PR', prefix: '+1939', name: "Puerto Rico"},
	{code: 'QA', prefix: '+974', name: "Qatar"},
	{code: 'RO', prefix: '+40', name: "Romania"},
	{code: 'RU', prefix: '+7', name: "Russia"},
	{code: 'RW', prefix: '+250', name: "Rwanda"},
	{code: 'RE', prefix: '+262', name: "Reunion"},
	{code: 'BL', prefix: '+590', name: "Saint Barthelemy"},
	{code: 'SH', prefix: '+290', name: "Saint Helena"},
	{code: 'KN', prefix: '+1869', name: "Saint Kitts and Nevis"},
	{code: 'LC', prefix: '+1758', name: "Saint Lucia"},
	{code: 'MF', prefix: '+590', name: "Saint Martin"},
	{code: 'PM', prefix: '+508', name: "Saint Pierre"},
	{code: 'VC', prefix: '+1784', name: "Saint Vincent"},
	{code: 'WS', prefix: '+685', name: "Samoa"},
	{code: 'SM', prefix: '+378', name: "San Marino"},
	{code: 'ST', prefix: '+239', name: "Sao Tome and Principe"},
	{code: 'SA', prefix: '+966', name: "Saudi Arabia"},
	{code: 'SN', prefix: '+221', name: "Senegal"},
	{code: 'RS', prefix: '+381', name: "Serbia"},
	{code: 'SC', prefix: '+248', name: "Seychelles"},
	{code: 'SL', prefix: '+232', name: "Sierra Leone"},
	{code: 'SG', prefix: '+65', name: "Singapore"},
	{code: 'SK', prefix: '+421', name: "Slovakia"},
	{code: 'SI', prefix: '+386', name: "Slovenia"},
	{code: 'SB', prefix: '+677', name: "Solomon Islands"},
	{code: 'SO', prefix: '+252', name: "Somalia"},
	{code: 'ZA', prefix: '+27', name: "South Africa"},
	{code: 'SS', prefix: '+211', name: "South Sudan"},
	{code: 'GS', prefix: '+500', name: "South Georgia"},
	{code: 'ES', prefix: '+34', name: "Spain"},
	{code: 'LK', prefix: '+94', name: "Sri Lanka"},
	{code: 'SD', prefix: '+249', name: "Sudan"},
	{code: 'SR', prefix: '+597', name: "Suriname"},
	{code: 'SJ', prefix: '+47', name: "Svalbard and Jan Mayen"},
	{code: 'SZ', prefix: '+268', name: "Swaziland"},
	{code: 'SE', prefix: '+46', name: "Sweden"},
	{code: 'CH', prefix: '+41', name: "Switzerland"},
	{code: 'SY', prefix: '+963', name: "Syrian Arab Republic"},
	{code: 'TW', prefix: '+886', name: "Taiwan"},
	{code: 'TJ', prefix: '+992', name: "Tajikistan"},
	{code: 'TZ', prefix: '+255', name: "Tanzania"},
	{code: 'TH', prefix: '+66', name: "Thailand"},
	{code: 'TL', prefix: '+670', name: "Timor-Leste"},
	{code: 'TG', prefix: '+228', name: "Togo"},
	{code: 'TK', prefix: '+690', name: "Tokelau"},
	{code: 'TO', prefix: '+676', name: "Tonga"},
	{code: 'TT', prefix: '+1868', name: "Trinidad and Tobago"},
	{code: 'TN', prefix: '+216', name: "Tunisia"},
	{code: 'TR', prefix: '+90', name: "Turkey"},
	{code: 'TM', prefix: '+993', name: "Turkmenistan"},
	{code: 'TC', prefix: '+1649', name: "Turks and Caicos Islands"},
	{code: 'TV', prefix: '+688', name: "Tuvalu"},
	{code: 'UG', prefix: '+256', name: "Uganda"},
	{code: 'UA', prefix: '+380', name: "Ukraine"},
	{code: 'AE', prefix: '+971', name: "United Arab Emirates"},
	{code: 'GB', prefix: '+44', name: "United Kingdom"},
	{code: 'US', prefix: '+1', name: "United States"},
	{code: 'UY', prefix: '+598', name: "Uruguay"},
	{code: 'UZ', prefix: '+998', name: "Uzbekistan"},
	{code: 'VU', prefix: '+678', name: "Vanuatu"},
	{code: 'VE', prefix: '+58', name: "Venezuela"},
	{code: 'VN', prefix: '+84', name: "Vietnam"},
	{code: 'VG', prefix: '+1284', name: "Virgin Islands, British"},
	{code: 'VI', prefix: '+1340', name: "Virgin Islands, U.S."},
	{code: 'WF', prefix: '+681', name: "Wallis and Futuna"},
	{code: 'YE', prefix: '+967', name: "Yemen"},
	{code: 'ZM', prefix: '+260', name: "Zambia"},
	{code: 'ZW', prefix: '+263', name: "Zimbabwe"}
]

export default COUNTRIES