import {auth} from '../utils/firebase'
import {toast} from 'react-toastify'
import {API_URL} from '../constants'
import axios from 'axios'

/**
 *
 * @param {string} message Message
 * @param {'success'|'error'} type Message type
 * @returns null
 */
export const showMessage = (message, type = 'error') => {

	toast[type](message, {
		autoClose: 2500,
		draggable: false,
		closeOnClick: true,
		pauseOnHover: true
	})

	return null

}

/**
 * XHR Request
 * @param {string} path URL where the request is performed
 * @param {object} data Additional request data
 * @param {boolean} needsAuth Should request send authorization headers
 */
export const request = async (path, data = {}, needsAuth = true) => {

	let options = {}

	if (needsAuth) {

		// get current user
		const {currentUser} = auth

		if (!currentUser)
			return {error: 'not_authenticated'}

		// set token
		options.headers = {Authorization: await currentUser.getIdToken()}

	}

	let response = await axios.post(API_URL + path, data, options)
		.then(response => response.data)
		.catch(() => ({error: 'api_failed'}))

	if (typeof response !== 'object')
		response = {error: 'api_failed'}

	return response

}