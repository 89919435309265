import {db, auth, storage} from '../utils/firebase'
import {showMessage} from '../utils'
import {unsubscribe} from './user'

import {
	query,
	where,
	limit,
	doc,
	orderBy,
	getDocs,
	startAfter,
	endBefore,
	onSnapshot,
	collection,
	writeBatch,
	serverTimestamp
} from 'firebase/firestore'

import {
	uploadBytes,
	getDownloadURL,
	ref as storageRef,
} from 'firebase/storage'

import {
	REPLIES,
	REPLIES_RESET,
	REPLIES_LOADED,
	REPLIES_LOADING,
	REPLIES_NEW_ITEM,
	REPLIES_LOADED_ALL,
	REPLIES_LOADED_MORE,
	REPLIES_PER_REQUEST,
  TICKETS,
	TICKETS_LOADED,
	TICKETS_LOADING,
	USER_LOADING,
} from '../constants'

/**
 * Fetch tickets
 */
export const fetchTickets = () => (dispatch, getState) => {

	const {user} = getState()

	if (unsubscribe.tickets)
		unsubscribe.tickets()

	dispatch({type: TICKETS_LOADING})

	let q = query(
		collection(db, TICKETS),
		where('account', 'in', user.accounts),
    where('owner', '==', user.objectId),
		orderBy('lastReplyAt', 'desc')
	)

	unsubscribe.tickets = onSnapshot(q, snapshot => {
		dispatch({
			type: TICKETS_LOADED,
			data: snapshot.docs.map(doc => doc.data())
		})
	})

}

/**
 * Ticket reply fetch
 * @param {string} ticketId Unique identifier
 * @param {boolean} forceRefresh Should replies be emptied
 */
export const fetchReplies = (ticketId, forceRefresh = false) => async (dispatch, getState) => {

	const {uid} = auth.currentUser || {}

	if (!uid)
		return false

	const {replies} = getState()

	// cand avem forceRefresh = true, dam dispatch la un eveniment nou
	if (forceRefresh)
		dispatch({type: REPLIES_RESET})

	if (replies.loading || replies.noMoreToLoad)
		return false

	dispatch({type: REPLIES_LOADING})

	let q = query(
		collection(db, TICKETS, ticketId, REPLIES),
		orderBy('createdAt', 'desc'),
		limit(REPLIES_PER_REQUEST)
	)

	if (replies.list.length) {

		q = query(q,
			startAfter(replies.list[0].createdAt),
			limit(REPLIES_PER_REQUEST)
		)

		return getDocs(q)
			.then(snapshot => {

				if (snapshot.empty)
					return dispatch({type: REPLIES_LOADED_ALL})

				let items = snapshot.docs.map(doc => doc.data())

				dispatch({
					type: REPLIES_LOADED_MORE,
					data: items.reverse()
				})

				if (items.length < REPLIES_PER_REQUEST)
					dispatch({type: REPLIES_LOADED_ALL})

			})

	} else {

		q = query(q, limit(REPLIES_PER_REQUEST))

		return getDocs(q)
			.then(snapshot => {

				if (!snapshot.empty)
					q = query(q, endBefore(snapshot.docs[0]))

				if (unsubscribe.replies)
					unsubscribe.replies()

				unsubscribe.replies = onSnapshot(q, snapshot =>
					snapshot.docChanges().forEach(change => {

						if (change.type === 'added')
							dispatch({
								type: REPLIES_NEW_ITEM,
								data: change.doc.data()
							})

					})
				)

				if (snapshot.empty) {
					dispatch({type: REPLIES_LOADED_ALL})
					return false
				}

				let items = snapshot.docs.map(doc => doc.data())

				dispatch({
					type: REPLIES_LOADED,
					data: items.reverse()
				})

				if (items.length < REPLIES_PER_REQUEST)
					dispatch({type: REPLIES_LOADED_ALL})

			})
			.catch(() => {})

	}

}

/**
 * Create a support ticket
 * @param {object} data Ticket details
 * @param {function} navigate Navigator
 */
export const create = (data, navigate) => async dispatch => {

	const {uid} = auth.currentUser || {}

	dispatch({
		type: USER_LOADING,
		data: true
	})

	// create references
	const ticketRef = doc(collection(db, TICKETS))
	const replyRef = doc(collection(ticketRef, 'replies'))

	// create batch
	const batch = writeBatch(db)

	// create ticket
	const ticketFields = {
		status: 			'open',
		account: 			data.account,
		objectId: 		ticketRef.id,
		subject: 			data.subject,
		lastReply: 		data.message,
		owner: 				uid,
		lastReplyBy: 	uid,
		lastReplyAt: 	serverTimestamp(),
		createdAt: 		serverTimestamp()
	}

	// create reply
	const replyFields = {
		objectId: 	replyRef.id,
		message: 		data.message,
		createdAt: 	serverTimestamp()
	}

	try {

		if (data.attachment) {

			let fileRef = storageRef(storage, `${TICKETS}/${ticketRef.id}/${replyRef.id}.jpg`)

			// upload file
			await uploadBytes(fileRef, data.attachment, {
				contentType: data.attachment.type,
				cacheControl: 'public, max-age=31540000'
			})

			// get download url
			await getDownloadURL(fileRef).then(url => replyFields.attachment = url)

		}

		// save ticket
		batch.set(ticketRef, ticketFields)

		// save reply
		batch.set(replyRef, replyFields)

		// commit changes
		await batch.commit()

	} catch (err) {

		dispatch({
			type: USER_LOADING,
			data: false
		})

		return showMessage(err.message)

	}

	dispatch({
		type: USER_LOADING,
		data: false
	})

	navigate(`/tickets/${ticketRef.id}`)

}

/**
 * Send reply within a ticket
 * @param {string} ticketId Unique identifier
 * @param {object} data Message details
 * @param {function} onSuccess On success trigger
 */
export const reply = (ticketId, data, callback) => async dispatch => {

	const {uid} = auth.currentUser || {}

	dispatch({
		type: USER_LOADING,
		data: true
	})

	// create references
	const ticketRef = doc(db, TICKETS, ticketId)
	const replyRef = doc(collection(ticketRef, 'replies'))

	// create batch
	const batch = writeBatch(db)

	// update ticket
	const ticketFields = {
		lastReplyBy: 	uid,
		status: 			'open',
		lastReply: 		data.message,
		lastReplyAt: 	serverTimestamp()
	}

	// create reply
	const replyFields = {
		objectId: 	replyRef.id,
		message: 		data.message,
		createdAt: 	serverTimestamp()
	}

	try {

		if (data.attachment) {

			let fileRef = storageRef(storage, `${TICKETS}/${ticketId}/${replyRef.id}.jpg`)

			// upload file
			await uploadBytes(fileRef, data.attachment, {
				contentType: data.attachment.type,
				cacheControl: 'public, max-age=31540000'
			})

			// get download url
			await getDownloadURL(fileRef).then(url => replyFields.attachment = url)

		}

		// save reply
		batch.set(replyRef, replyFields)

		// save ticket
		batch.update(ticketRef, ticketFields)

		// commit changes
		await batch.commit()

	} catch (err) {

		dispatch({
			type: USER_LOADING,
			data: false
		})

		return showMessage(err.message)

	}

	dispatch({
		type: USER_LOADING,
		data: false
	})

	callback()

}